// ColorsMixin.ts

import { Component, Vue } from 'vue-property-decorator';

@Component
export class ColorsMixin extends Vue {
    moreColors: boolean = false;
    baseColors = [
        [
            '#FFFFFF', // white
            '#000000', // black
            '#FF0000', // red
            '#00FF00', // green
            '#0000FF', // blue
        ],
        [
            '#FFD800', // yellow
            '#B200FF', // purple
            '#00FFFF', // cyan
            '#FF6A00', // orange
            '#FF00DC', // pink
        ],
    ]
    
    teamColors = [
        [
            '#004d98', // Barcelona Blau
            '#6CABDD', // Manchester City
            '#0097DB', // Zenit
            '#1e71b8', // Atalanta
            '#034694', // Chelsea
        ],
        [
            '#00428c', // Porto
            '#010E80', // Inter Milan
            '#004170', // PSG
            '#272e61', // Atletico
            '#132257', // Tottenham
        ],
        [
            '#a50044', // Barcelona Grana
            '#fb090b', // AC Milan
            '#f43333', // Sevilla
            '#EF0107', // Arsenal
            '#E83030', // Benfica
        ],
        [
            '#DA291C', // Manchester United
            '#D2122E', // Ajax
            '#c8102E', // Liverpool
            '#B81137', // Toronto FC
            '#a90432', // Galatasaray
        ],
        [
            '#65B32E', // Wolfsburg
            '#5D9741', // Seattle
            '#018749', // Celtic
            '#FFF200', // Norwich
            '#FDE100', // Dortmund
        ],
        [
            '#F0612C', // Shaktar
            '#f7b5cd', // Miami
            '#633492', // Orlando
            '#E4E5E6', // Minnesota
            '#241F20', // Newcastle
        ],
    ];

    transpose<T>(matrix: T[][]): T[][] {
        return matrix[0].map((_, colIndex) => matrix.map(row => row[colIndex]));
    }
    get ColorSwatches(): Array<Array<string>> {
        if( this.moreColors ) return undefined; // show all colors
        return this.transpose(this.baseColors.concat(this.teamColors));
    }

    get colorWhite(): string {
        return '#FFFFFF';
    }
    get colorBlack(): string {
        return '#000000';
    }
    isCloserToBlackOrWhite(hex: string, opposite: boolean = false): string {
        hex = hex.slice(0,7);
        // Validate hex color code
        if (!/^#([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6})$/.test(hex)) {
            throw new Error("Invalid hex color code");
        }
    
        // Expand shorthand hex code (e.g., #123 -> #112233)
        if (hex.length === 4) {
            hex = `#${hex[1]}${hex[1]}${hex[2]}${hex[2]}${hex[3]}${hex[3]}`;
        }
    
        // Extract RGB components
        const r = parseInt(hex.slice(1, 3), 16);
        const g = parseInt(hex.slice(3, 5), 16);
        const b = parseInt(hex.slice(5, 7), 16);
    
        // Calculate brightness using the luminance formula
        const brightness = 0.299 * r + 0.587 * g + 0.114 * b;
    
        // Compare brightness to 128
        
        if( opposite ) return ( brightness < 128)?  this.colorWhite : this.colorBlack;
        return ( brightness < 128)? this.colorBlack : this.colorWhite;
    }    
}