// Icons

import { mdiVolumeMedium } from "@mdi/js";

export const baIcons = {
    none: 'mdi-none',
    accolades: 'mdi-medal',
    account: 'mdi-account', 
    accountPending: 'mdi-account-clock',
    addAccount: 'mdi-account-plus',
    addItem: 'mdi-plus-circle',
    addLink: 'mdi-link-plus',
    addTeam: 'mdi-plus-circle-outline',
    agendaView: 'mdi-calendar-text',
    accountAdd: 'mdi-account-plus',
    accountEdit: 'mdi-account-edit',
    accountRemove: 'mdi-account-remove',
    agreement: 'mdi-file-sign',
    alert: 'mdi-alert-circle-outline',
    archive: 'mdi-archive', 
    archiveAdd: 'mdi-archive-plus', 
    archiveOff: 'mdi-archive-off',
    archiveRemove: 'mdi-archive-cancel-outline', 
    assessments: 'mdi-calendar-check',
    athlete: 'mdi-run',
    athletes: 'mdi-run-fast',
    athleteLink: 'mdi-link-variant-plus',
    athleteMove: 'mdi-account-arrow-right',
    athleteRemove: 'mdi-account-remove',
    athleteReport: 'mdi-clipboard-account-outline',
    attachment: 'mdi-paperclip',
    bell: 'mdi-bell',
    bio: 'mdi-text-account',
    calendar: 'mdi-calendar', 
    calendarShrink: 'mdi-calendar-collapse-horizontal',
    calendarExpand: 'mdi-calendar-expand-horizontal',
    calendarExport: 'mdi-calendar-export',
    calendarLink: 'mdi-calendar-start',
    calendarSee: 'mdi-table-headers-eye',
    calendarUpload: 'mdi-calendar-import',
    calendarView: 'mdi-calendar-month',
    calendarYear: 'mdi-calendar-blank',
    checkmark: 'mdi-check',
    checkMaybe: 'mdi-progress-question',
    checkNo: 'mdi-checkbox-blank-outline',
    checkYes: 'mdi-check-circle',
    checkboxNo: 'mdi-checkbox-blank-outline',
    checkboxYes: 'mdi-checkbox-marked',
    city: 'mdi-home-city',
    circle: 'mdi-circle',
    circleOutline: 'mdi-circle-outline',
    clipMaker: 'mdi-movie-open',
    close: 'mdi-close',
    closeButton: 'mdi-close-circle',
    closeDialog: 'mdi-close',
    cloudUpload: 'mdi-cloud-upload-outline',
    collapse: 'mdi-chevron-up',
    collapseHighlight: 'mdi-chevron-up-circle',
    color: 'mdi-palette',
    comment: 'mdi-comment-processing-outline',
    commentOff: 'mdi-comment-off-outline',
    communication: 'mdi-email',
    compare: 'mdi-chart-bar',
    connect: 'mdi-human-greeting-proximity',
    copy: 'mdi-content-copy',
    copyColor: 'mdi-format-color-fill',
    country: 'mdi-image-marker',
    creditCard: 'mdi-credit-card-outline',
    cut: 'mdi-content-cut',
    dataBase: 'mdi-database-search',
    database: 'mdi-database-search',
    databaseAlert:'mdi-database-alert',
    databaseImport: 'mdi-database-import-outline',
    dataEntry: 'mdi-notebook-edit',
    dashboard: 'mdi-view-dashboard',
    delete: 'mdi-delete',
    deletedHide: 'mdi-database-eye-off',
    deletedShow: 'mdi-database-eye',
    deleteHover: 'mdi-delete-empty',
    deleteOutline: 'mdi-delete-outline',
    depthChartDelete: 'mdi-notebook-remove-outline',
    depthChartEdit: 'mdi-notebook-edit',
    depthChartDuplicate: 'mdi-notebook-multiple',
    depthChartNew: 'mdi-notebook-plus',
    dislikeOutline: 'mdi-thumb-down-outline',
    dislike: 'mdi-thumb-down',
    document: 'mdi-file-document',
    documents: 'mdi-file-document-multiple',
    dollar: 'mdi-currency-usd',
    download: 'mdi-download',
    duplicate: 'mdi-content-duplicate',
    edit: 'mdi-pencil',
    editAccount: 'mdi-account-edit', 
    editImage: 'mdi-image-edit',
    editNote: 'mdi-note-edit',
    editRoster: 'mdi-clipboard-edit',
    education: 'mdi-book-education',
    email: 'mdi-email',
    emailAll: 'mdi-email-multiple',
    emailAdd: 'mdi-email-plus',
    emailResend: 'mdi-email-sync-outline',
    emailSent: 'mdi-email-check',
    error: 'mdi-alert-circle',
    event: 'mdi-calendar-star',
    eventAdd: 'mdi-calendar-plus',
    eventShowcase: 'mdi-calendar-star-four-points',
    events: 'mdi-calendar-multiple-check',
    expand: 'mdi-chevron-down',
    expandHighlight: 'mdi-chevron-down-circle',
    expandScreen: 'mdi-arrow-expand-all',
    experience: 'mdi-briefcase-account',
    facebook: 'mdi-facebook',
    family: 'mdi-human-male-female-child',
    feature: 'mdi-star-circle',
    featured: 'mdi-star-circle-outline',
    filters: 'mdi-filter-multiple-outline',
    filterOff: 'mdi-filter-off',
    gift: 'mdi-gift',
    giftOpen: 'mdi-gift-open',
    gpa: 'mdi-cast-education',
    grid: 'mdi-grid',
    gridLarge: 'mdi-grid-large',
    heart: 'mdi-heart',
    heartOutline: 'mdi-heart-outline',
    height: 'mdi-human-male-height',
    help: 'mdi-comment-question-outline',
    home: 'mdi-home',
    imageUpload: 'mdi-file-image-plus',
    information: 'mdi-information-outline',
    informationHover: 'mdi-information',
    injury: 'mdi-bandage',
    instagram: 'mdi-instagram',
    invite: 'mdi-email-plus',
    jobs: 'mdi-bulldozer',
    like: 'mdi-thumb-up',
    likeOutline: 'mdi-thumb-up-outline',
    likeUndecided: 'mdi-thumbs-up-down-outline',
    lightning: 'mdi-flash',
    list: 'mdi-format-list-bulleted-square',
    login: 'mdi-login',
    logout: 'mdi-logout',
    link: 'mdi-link-box',
    location: 'mdi-city',
    locked: 'mdi-lock-outline',
    lockOpen: 'mdi-lock-open-outline',
    map: 'mdi-map',
    mapMarker: 'mdi-map-marker',
    menu: 'mdi-menu',
    meetVirtual: 'mdi-laptop-account',
    merge: 'mdi-merge',
    mindset: 'mdi-head-snowflake',
    mindsetOutline: 'mdi-head-snowflake-outline',
    more: 'mdi-dots-vertical',
    moveBottom: 'mdi-format-vertical-align-bottom',
    moveDown: 'mdi-arrow-down-thick',
    moveTop: 'mdi-format-vertical-align-top',
    moveUp: 'mdi-arrow-up-thick',
    next: 'mdi-chevron-right',
    doubleNext: 'mdi-chevron-double-right',
    observationAdd: 'mdi-note-plus',
    openLink: 'mdi-open-in-new',
    organization: 'mdi-sitemap-outline',
    organizations: 'mdi-sitemap',
    pageEdit: 'mdi-application-edit',
    partner: 'mdi-handshake',
    paymentFree: 'mdi-ticket-outline',
    payments: 'mdi-account-credit-card',
    pdf: 'mdi-file-pdf-box',
    phone: 'mdi-phone',
    photo: 'mdi-image',
    photoAdd: "mdi-camera-plus-outline",
    plus: 'mdi-plus',
    previous: 'mdi-chevron-left',
    price: 'mdi-cash',
    printView: 'mdi-printer-eye',
    printViewOff: 'mdi-printer-off',
    pro: 'mdi-professional-hexagon',
    product: 'mdi-cube',
    profile: 'mdi-badge-account',
    performance: 'mdi-chart-bar-stacked',
    physical: 'mdi-run',
    qrcode: 'mdi-qrcode',
    radar: 'mdi-radar',
    record: 'mdi-clipboard-account',
    recruit: 'mdi-account-eye',
    register: 'mdi-account-box',
    removeAccount: 'mdi-account-remove',
    removeAccountOutline: 'mdi-account-remove-outline',
    removeLink:'mdi-link-off',
    reportGame: 'mdi-chart-timeline',
    reportWrite: 'mdi-notebook-edit',
    return: 'mdi-chevron-left',
    sale: 'mdi-sale',
    save: 'mdi-content-save',
    saveAlert: 'mdi-content-save-alert',
    schedule: 'mdi-calendar-multiselect',
    school: 'mdi-school',
    scoutingReport: 'mdi-clipboard-list-outline',
    search: 'mdi-magnify',
    security: 'mdi-shield-lock',
    secureUser: 'mdi-shield-account',
    selfReport: 'mdi-account-voice',
    settings: 'mdi-cog',
    share: 'mdi-share-variant',
    skip: 'mdi-skip-next',
    soccer: 'mdi-soccer',
    sponsor: 'mdi-account-cash',
    sports: 'mdi-trophy',
    staff: 'mdi-badge-account',
    star: 'mdi-star',
    subscription: 'mdi-account-reactivate',
    suspension: 'mdi-account-off',
    square: 'mdi-square',
    synchronize: 'mdi-sync',
    syncUser: 'mdi-account-sync',
    tag: 'mdi-tag-outline',
    team: 'mdi-account-group',
    teamCreate: 'mdi-account-multiple-plus-outline',
    teamJoin: 'mdi-account-multiple-plus',
    teamLess: 'mdi-account-arrow-up',
    teamMore: 'mdi-account-arrow-down',
    teamSelect: 'mdi-account-multiple-check',
    teamView: 'mdi-account-eye',
    teams: 'mdi-account-group-outline',
    testScore: 'mdi-chair-school',
    ticket: 'mdi-ticket',
    time: 'mdi-clock-outline',
    twitter: 'mdi-twitter',
    undo: 'mdi-undo',
    unsubscribe: 'mdi-account-cancel',
    upload: 'mdi-upload',
    uploadBox: 'mdi-upload-box',
    uploadFile: 'mdi-file-upload',
    update: 'mdi-update',
    user: 'mdi-account',
    users: 'mdi-account-multiple',
    video: 'mdi-video',
    videoAdd: 'mdi-video-plus',
    videoForward5: 'mdi-fast-forward-5',
    videoLink: 'mdi-video-outline',
    videoOff: 'mdi-video-off',
    videoPlay: 'mdi-play',
    videoPlayCircle: 'mdi-play-circle',
    videoPause: 'mdi-pause',
    videoRewind5: 'mdi-rewind-5',
    view: 'mdi-eye',
    notViewable: 'mdi-eye-off',
    visibility: 'mdi-account-eye',
    visibilityOff: 'mdi-account-cancel',
    volumeMute: 'mdi-volume-mute',
    volumeLow: 'mdi-volume-low',
    volumeMedium: 'mdi-volume-medium',
    volumeHigh: 'mdi-volume-high',
    website: 'mdi-web',
    weight: 'mdi-weight',
    whistle: 'mdi-whistle',

    bestEducation: 'mdi-book-education',
};
